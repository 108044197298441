import clsx from 'clsx'
import React from 'react'
import Plus from '../../../images/plus.inline.svg'

const ButtonClose = ({ size = 'regular', setActive }) => {
  return (
    <button
      className={clsx('btn btn--rounded btn--close relative ', `-btn--${size}`)}
      onClick={() => setActive(false)}
      aria-label="close"
    >
      <div className={clsx('icon  close', `icon--${size}`)}>
        {/* <Plus /> */}
        <span className="icon-close"></span>
      </div>
    </button>
  )
}

export default ButtonClose
