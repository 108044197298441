import React from 'react'
import Marquee from 'react-fast-marquee'

const UiMarquee = ({ headline }) => {
  return (
    <Marquee
      gradient={false}
      speed={40}
      play={true}
      className="bg-yellow font-medium"
    >
      {new Array(20).fill(0).map((v, i) => (
        <div key={i} className="px-6 headline">
          {headline}
        </div>
      ))}
    </Marquee>
  )
}

export default UiMarquee
