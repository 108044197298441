import React from 'react'
import clsx from 'clsx'

const ButtonContext = ({ size, dark = false, children }) => {
  return (
    <div
      className={clsx(`btn btn--${size} ${dark ? 'btn--dark' : ''} min-w-0-`)}
    >
      {children}
    </div>
  )
}

export default ButtonContext
