import React from 'react'
import { _excerptify, _localizeField } from '../../core/utils'
import SanityImage from '../SanityImage'
import SanityTexte from '../SanityTexte'
import Thumbnail from './thumbnails/Thumbnail'

const MediaCard = ({ input, expanded }) => {
  console.log(input)
  const text = _localizeField(input.text)
  const excerpt = _excerptify(text)
  // console.log(input)
  return (
    <div className="media-card ui-max-width mb-1">
      <div className="title mb-1 text-sm">{_localizeField(input.title)}</div>
      <div className="flex items-center-">
        <div className="media shrink-0 mb-1_5 mr-1_5">
          <div className="media--small">
            <Thumbnail size={48} input={input.image} />
          </div>
          <div className="media--full round">
            {input.image?.asset && <SanityImage image={input.image} />}
          </div>
        </div>
        <div className="px-1e-">
          <div className="text-full">
            <SanityTexte input={_localizeField(input.text)} />
          </div>
          <div className="text-excerpt ">
            <p>{excerpt}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaCard
