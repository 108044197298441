// module.exports = {
//   sanity: {
//     projectId: process.env.GATSBY_SANITY_PROJECT_ID || '',
//     dataset:
//       process.env.NODE_ENV === 'production'
//         ? process.env.GATSBY_SANITY_PROJECT_DATASET || 'production'
//         : 'production',
//     // dataset: 'production',
//   },
// }

const s = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || '',
    dataset:
      process.env.NODE_ENV === 'production'
        ? process.env.GATSBY_SANITY_PROJECT_DATASET || 'production'
        : 'preprod',
    // dataset: 'production',
  },
}
export default s
