import React, { CSSProperties } from 'react'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { GatsbyImage, getSrc, getSrcSet } from 'gatsby-plugin-image'

// const sanityConfig = { projectId: "s2j9sc95", dataset: "production" };
import sanityConfig from '../../config/sanity'

type Props = {
  image: {
    asset: Queries.SanityImageAsset | any
  }
  caption?: any
  objectFit?: string
  thumbnail?: boolean
  height?: number
  _onLoad?: Function
}
const SanityThumbnail = ({
  image,
  objectFit = 'contain',
  height = 200,
  _onLoad, // showPlaceholder = true,
}: Props) => {
  // console.log(_onLoad)
  const { asset } = image
  // const { metadata } = asset
  const metadata = asset?.metadata
  const aspectRatio = metadata?.dimensions
    ? metadata?.dimensions?.aspectRatio
    : '1'
  // let options = {}
  let options = {
    height: height,
    width: height * aspectRatio,
    fit: 'FILLMAX',
    // placeholder: 'blurred',
    placeholder: 'dominantColor',
    formats: ['auto', 'webp'],
  }

  // console.log(sanityConfig.sanity)
  let dataset = asset.url.indexOf('preprod') > -1 ? 'preprod' : 'production'
  const config = {
    projectId: sanityConfig.sanity.projectId,
    dataset: dataset,
  }
  // console.log(asset)
  const imageData = getGatsbyImageData(asset, options, config)
  // const src = getSrc(asset)
  // const srcSet = getSrcSet(asset)
  // console.log(src)
  return (
    <div
      className="pointer-events-none"
      style={{
        aspectRatio: `${options.width} / ${options.height}`,
        height: options.height,
        width: '100%',
      }}
    >
      {imageData && (
        <GatsbyImage
          image={imageData}
          imgStyle={{
            aspectRatio: `${options.width} / ${options.height}`,
            objectFit: objectFit,
          }}
          onLoad={_onLoad}
          loading="lazy"
          data-filename={asset.filename}
        />
      )}
    </div>
  )
}

export default SanityThumbnail
