import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const duration = 0.15

const variants = {
  initial: {
    opacity: 0,
    // y: 10,
  },
  enter: {
    opacity: 1,
    // y: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    // y: -10,
    transition: {
      duration: duration,
    },
  },
}

const PageTransition = ({ location, children }) => {
  return (
    <AnimatePresence>
      <motion.div
        className="page-transition"
        key={location.pathname}
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
        // ref={scrollRef}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default PageTransition
