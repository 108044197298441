import './src/styles/tailwind.css'
import './src/styles/index.scss'
import type { GatsbyBrowser } from 'gatsby'
import {
  // _wrapRootElement,
  _wrapPageElement,
  _onClientEntry,
  _onRouteUpdate,
  _onPreRouteUpdate,
} from './gatsby-shared'

// export const wrapRootElement = _wrapRootElement
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] =
  _wrapPageElement
export const onClientEntry: GatsbyBrowser['onClientEntry'] = _onClientEntry
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = _onRouteUpdate
export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] =
  _onPreRouteUpdate
