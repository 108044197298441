import SanityTexte from 'components/SanityTexte'
import { _localizeField } from 'core/utils'
import React from 'react'

const AeaiCredits = ({ input }) => {
  return (
    <div className="aeai-naasner-credits">
      <SanityTexte input={_localizeField(input)} />
    </div>
  )
}

export default AeaiCredits
