import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { Link } from 'gatsby'

type Props = {
  path: string
  size: string
  rounded?: boolean
  children: ReactNode
  active?: boolean
  onClick?: Function
  hoverable?: boolean
  btnStyle: object | any
  shaded?: boolean
}
const ButtonInternalLink = ({
  path,
  size,
  rounded,
  children,
  active = false,
  onClick,
  hoverable = true,
  btnStyle,
  shaded = false,
}: Props) => {
  return (
    <Link
      to={path}
      className={clsx(
        'btn',
        size ? `btn--${size}` : '',
        rounded ? 'btn--rounded' : '',
        active ? 'is-active' : '',
        hoverable ? '' : 'no-hover',
        shaded ? 'is-shaded' : ''
      )}
      onClick={onClick}
      style={btnStyle}
    >
      <div className="ellipsis">{children}</div>
    </Link>
  )
}

export default ButtonInternalLink
