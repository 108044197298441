import clsx from 'clsx'
import { Link } from 'gatsby'
import { publish } from 'pubsub-js'
import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import useLocale from '../../contexts/LocaleWrapper'
import { _linkResolver, _localizeField, _localizeText } from '../../core/utils'
// import Plus from '../../images/plus.inline.svg'

// const wait = async delay => {
//   return new Promise(resolve => setTimeout(resolve, delay))
// }

const Container = styled.div`
  form {
    position: sticky;
    top: -1rem;
    z-index: 50;
  }
  .results {
    .col-title {
      flex: 1;
      min-width: 0;
    }

    .result-item.flex > div {
      @media all and (max-width: 500px) {
        min-width: 0;
        flex: 1;
      }
      @media all and (min-width: 500px) and (max-width: 1080px) {
        &:last-child {
          /* flex: 0; */
        }
      }
    }
    .bb::after {
      opacity: 1;
    }
  }
`

const Form = styled.form`
  input,
  button {
    padding: 0.5em 1rem;
    /* padding: 1.5em; */
    /* line-height: 3.3; */
    line-height: unset;
    max-height: 3em;
  }
  input {
    color: black;
    background-color: transparent;
    /* padding-top: 0.3em; */
    flex: 2;
    ::placeholder {
      color: var(--color-black-40);
    }
    @media all and (max-width: 768px) {
      max-width: calc(100% - 9rem);
    }
  }
  button {
    cursor: pointer;
    svg {
      width: 1em;
      height: 1em;
    }
  }
  .action {
    /* border-left: 1px solid rgba(0, 0, 0, 0.1); */
    min-width: 9rem;
    display: flex;
    justify-content: flex-end;
  }
`

const ResultItem = ({ input }) => {
  const festivals =
    input.festivalYear && input.festivalYear?.length > 0
      ? input.festivalYear.slice(0, 3)
      : []
  festivals.sort((a, b) => b - a)
  return (
    <li className="bb">
      <Link to={_linkResolver(input)}>
        {/* <pre>{JSON.stringify(input)}</pre> */}
        <div className="result-item flex justify-between gap-1">
          <div className="md:w-1/2 ">
            <div>
              <div className="flex items-center">
                {input.media && (
                  <div className="media shrink-0 mr-1">
                    <div className="thumbnail thumbnail--small">
                      <img src={input.media} alt="" />
                    </div>
                  </div>
                )}
                <div className="mr-1 py-1_5 col-title flex-1 shrink-0 ui-max-width font-medium">
                  {input.title && (
                    <div className="title ellipsis ">
                      {_localizeField(input.title)}
                    </div>
                  )}
                  {input.name && <div>{_localizeField(input.name)}</div>}
                  {/* <div className="subtitle">{_linkResolver(input)}</div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 hidden-xs ">
            <div className="flex justify-between py-1_5">
              <div className="">
                <div className="path">{_linkResolver(input)}</div>
              </div>
              {input.date && (
                <div className="pl-1 text-right">{input.date}</div>
              )}
              {!input.date && festivals.length > 0 && (
                // <ul className="flex list-string">
                //   {festivals.map((item, i) => (
                //     <span className="pl-1 text-right text-black-40-" key={i}>
                //       {item}{' '}
                //     </span>
                //   ))}
                // </ul>

                <ul className="festivals flex list-with-comma">
                  {festivals?.map((item: string, i: number) => (
                    <li key={i}>{item}</li>
                  ))}
                  {input.festivalYear.length > 3 && <li>...</li>}
                </ul>
              )}
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}

const SearchSanity = () => {
  const result = []
  const [search, setSearch] = useState('')
  // const [focus, setFocus] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState(result)
  const [error, setError] = useState<string>('')
  const [submited, setSubmited] = useState<boolean>(false)
  const inputRef = useRef()

  const { locale } = useLocale()
  // console.log(locale)
  useEffect(() => {
    // console.log(loading)
    publish('IS_LOADING', loading)
  }, [loading])

  useEffect(() => {
    if (search === '') {
      setData([])
      setSubmited(false)
    }
  }, [search])

  const _onSubmit = async evt => {
    evt.preventDefault()
    // console.log(evt, locale)
    // setSubmited(true)
    setSubmited(true)
    setLoading(true)
    setData([])

    // await wait(5000)
    // return
    const body = { s: search, locale: locale }
    console.log(body)
    fetch('/api/search', {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then(e => e.json())
      .then(e => {
        console.log(e)
        setData(e)
        // setSubmited(true)
        // setLoading(false)
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }

  // const _reset = () => {
  //   setData([])
  //   setSearch('')
  //   setSubmited(false)
  // }

  // console.log(data)
  const resultLabel =
    data.length > 1
      ? _localizeText('searchResults')
      : _localizeText('searchResult')

  const loadingLabel = _localizeText('loading')
  const noResultsLabel = _localizeText('noResults')
  const pagetypeLabel = _localizeText('pagetype')
  const festivalLabel = _localizeText('festival')

  const isSearching = search !== '' && submited && loading === false
  return (
    <Container>
      <div className="label mb-1 text-sm-">{_localizeText('search')}</div>
      <Form
        onSubmit={_onSubmit}
        className="rounded-md- bg-orange-shaded text-lg"
      >
        <div className="flex justify-between">
          <input
            ref={inputRef}
            type="text"
            name="s"
            placeholder={`${_localizeText('search')} ...`}
            value={search}
            autoComplete="off"
            onChange={e => setSearch(e.target.value)}
            onMouseEnter={() => inputRef.current.focus()}
            onKeyDown={() => {
              if (submited) setSubmited(false)
            }}
          />
          <div className="action">
            <button
              type="submit"
              className={clsx(
                '',
                submited && isSearching ? 'text-black-40' : ''
              )}
            >
              <svg
                id="search"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
              >
                <g id="search-2" data-name="search">
                  <path
                    id="Path_1416"
                    data-name="Path 1416"
                    d="M10.191,8.978l-2.5-2.5a4.051,4.051,0,1,0-1.21,1.211l2.5,2.5a.214.214,0,0,0,.3,0l.907-.907A.213.213,0,0,0,10.191,8.978ZM1.538,4.291A2.757,2.757,0,1,1,4.3,7.049,2.761,2.761,0,0,1,1.538,4.291Z"
                    transform="translate(-0.254 -0.251)"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </Form>

      {loading && (
        <div className="status py-1_5 px-1 bg-white bb- font-medium">
          {loadingLabel}...
        </div>
      )}
      {error && (
        <div className="status py-1_5 px-1 bg-white bb- font-medium">
          {error.responseBody}
        </div>
      )}

      {isSearching && (
        <div className="results py-1_5 px-1 bg-white">
          <>
            {data.length === 0 && (
              <div className="label pb-1- text-sm bb- font-medium">
                {/* <span>{noResultsLabel}</span> */}
                {data.length} {resultLabel}
              </div>
            )}

            {data.length > 0 && (
              <div className="header flex gap-1 pb-1 text-sm bb">
                <div className="label w-1/2">
                  <span>
                    {data.length} {resultLabel}
                  </span>
                </div>
                <div className="w-1/2 flex justify-end md:justify-between "></div>
              </div>
            )}
          </>
          <ul>
            {data.map((item, i) => (
              <ResultItem key={i} input={item} />
            ))}
          </ul>
        </div>
      )}
    </Container>
  )
}

export default SearchSanity
