import React from 'react'
import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  from {
    opacity: 0;
    /* transform: translateY(-6px); */
  }

  to {
    opacity: 1;
    /* transform: translateY(0px); */
  }
`

const Loader = styled.div`
  display: inline-block;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-05);
`
interface Props {
  animationDelay: string
  opacity: string
}

const LoaderDot = styled.div<Props>`
  width: var(--space-1);
  height: var(--space-1);
  flex-basis: var(--space-1);
  border-radius: 100%;
  background-color: black;
  opacity: ${p => p.opacity};
  animation: ${pulse} 500ms ease-out infinite;
  animation-delay: ${p => p.animationDelay};
  animation-fill-mode: forwards;
  animation-direction: alternate-reverse;
`

const LoaderOverlay = () => {
  return (
    <div className="loader-overlay fixed inset-0 bg-white- z-50 pointer-events-none">
      <div className="flex-center h-full">
        <Loader className="lds-hourglass ">
          <LoaderDot opacity="0.1" animationDelay="0ms" />
          <LoaderDot opacity="0.8" animationDelay="200ms" />
          <LoaderDot opacity="1" animationDelay="400ms" />
        </Loader>
      </div>
    </div>
  )
}

export default LoaderOverlay
