import React, { ReactNode } from 'react'
import clsx from 'clsx'

interface Props {
  children: ReactNode
  size?: string
  rounded?: boolean
  onClick?: Function
  btnStyle?: object
  active?: boolean
  hoverable?: boolean
  shaded?: boolean
  yellow?: boolean
}

const Button = ({
  size = 'regular',
  rounded,
  children,
  active = false,
  onClick,
  hoverable,
  btnStyle,
  shaded = false,
  yellow = false,
}: Props) => {
  return (
    <button
      className={clsx(
        'btn',
        size ? `btn--${size}` : '',
        rounded ? 'btn--rounded' : '',
        active ? 'is-active' : '',
        hoverable ? '' : 'no-hover',
        shaded ? 'is-shaded' : '',
        yellow ? 'btn--yellow' : ''
      )}
      onClick={onClick}
      style={btnStyle}
    >
      {/* <span className="pointer-events-none ">{children}</span> */}
      {children}
    </button>
  )
}

export default Button
