exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-archive-v-2-tsx": () => import("./../../../src/templates/ArchiveV2.tsx" /* webpackChunkName: "component---src-templates-archive-v-2-tsx" */),
  "component---src-templates-basic-article-tsx": () => import("./../../../src/templates/BasicArticle.tsx" /* webpackChunkName: "component---src-templates-basic-article-tsx" */),
  "component---src-templates-basic-feed-tsx": () => import("./../../../src/templates/BasicFeed.tsx" /* webpackChunkName: "component---src-templates-basic-feed-tsx" */),
  "component---src-templates-festival-info-tsx": () => import("./../../../src/templates/FestivalInfo.tsx" /* webpackChunkName: "component---src-templates-festival-info-tsx" */),
  "component---src-templates-festival-locations-tsx": () => import("./../../../src/templates/FestivalLocations.tsx" /* webpackChunkName: "component---src-templates-festival-locations-tsx" */),
  "component---src-templates-festival-people-tsx": () => import("./../../../src/templates/FestivalPeople.tsx" /* webpackChunkName: "component---src-templates-festival-people-tsx" */),
  "component---src-templates-festival-tsx": () => import("./../../../src/templates/Festival.tsx" /* webpackChunkName: "component---src-templates-festival-tsx" */),
  "component---src-templates-kalender-tsx": () => import("./../../../src/templates/Kalender.tsx" /* webpackChunkName: "component---src-templates-kalender-tsx" */),
  "component---src-templates-location-tsx": () => import("./../../../src/templates/Location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-people-tsx": () => import("./../../../src/templates/People.tsx" /* webpackChunkName: "component---src-templates-people-tsx" */),
  "component---src-templates-production-tsx": () => import("./../../../src/templates/Production.tsx" /* webpackChunkName: "component---src-templates-production-tsx" */),
  "component---src-templates-serie-tsx": () => import("./../../../src/templates/Serie.tsx" /* webpackChunkName: "component---src-templates-serie-tsx" */)
}

